import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyImage = (props) => {

  return (
    <div>
      <LazyLoadImage
        effect="blur"
        alt={props.alt}
        height={props.height}
        src={props.src}
        width={props.width}
        title={props.title}
      />
    </div>
  );
};

export default MyImage;
