import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import OtherHeader from "./Components/Layout/Header";
import HomeHeader from "./Components/Layout/HomeHeader";

import Footer from "./Components/Layout/Footer";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Home = lazy(() => import("./Pages/Home"));
const About = lazy(() => import("./Pages/About"));
const Contact = lazy(() => import("./Pages/Contact"));
const Tours = lazy(() => import("./Pages/Tours"));
const Transfer = lazy(() => import("./Pages/Transfer"));
const TourDetails = lazy(() => import("./Pages/TourDetails"));
const NoMatch = lazy(() => import("./Components/NoMatch"));

const App = () => {
  const location = useLocation();
  return (
    <>
      <div className="page-wrapper">
        {location.pathname === "/" ? <HomeHeader /> : <OtherHeader />}

        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/transfer" element={<Transfer />} />
            <Route path="/tours" element={<Tours />} />
            <Route path="/tours/:slug" element={<TourDetails />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Suspense>
        <WhatsAppWidget
          message={`Hello! 👋🏼 \n\nWhat can we do for you?`}
          phoneNumber="+3546979385"
        />
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Footer />
      </div>
    </>
  );
};

export default App;
