import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const location = useLocation();

  return (
    <ul className="navigation clearfix">
      <li className={location.pathname === "/" ? "current" : ""}>
        <NavLink to="/"> Home</NavLink>
      </li>
      <li className={location.pathname === "/about" ? "current" : ""}>
        <NavLink to="/about"> About</NavLink>
      </li>
      <li className={location.pathname === "/tours" ? "current" : ""}>
        <NavLink to="/tours"> Tours</NavLink>
      </li>
      <li className={location.pathname === "/transfer" ? "current" : ""}>
        <NavLink to="/transfer"> Transfer</NavLink>
      </li>
      <li className={location.pathname === "/contact" ? "current" : ""}>
        <NavLink to="/contact">Contact</NavLink>
      </li>
    </ul>
  );
};

export default Nav;
