import React from "react";
import { NavLink } from "react-router-dom";
import tours from "../../data/tours.json";
import transfer from "../../data/transfer.json";

import MyImage from "../MyImage";

const Footer = () => {
  return (
    <div>
      <footer className="main-footer">
        <div className="upper-section">
          <div className="auto-container">
            <div className="content-box">
              <div className="row clearfix">
                <div className="footer-column col-xl-4 col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget about-widget">
                    <div className="footer-logo">
                      <NavLink to="index.html'" title="Elegant Tours">
                        <img
                          src={require("../../Assets/images/logo.png")}
                          style={{ height: "50px" }}
                          alt=""
                          title="Travilo"
                        />
                      </NavLink>
                    </div>
                    <div className="footer-info">
                      <ul className="info">
                        <li className="address">
                          <a
                            target="_blank"
                            href="https://www.google.com/maps/place/Su%C3%B0urh%C3%B3lar+18+111,+111+Reykjav%C3%ADk,+Iceland/@64.1072716,-21.8162763,17z/data=!3m1!4b1!4m5!3m4!1s0x48d673725d21528b:0x3b4d690b08e53447!8m2!3d64.1072716!4d-21.8137014"
                            rel="noreferrer">
                            <i className="icon fa fa-map-marker-alt"></i>{" "}
                            Sudurholar 18. 111 Reykjavik. Iceland.
                          </a>
                        </li>
                        <li className="phone">
                          <a href="tel:+354-8693017">
                            <i className="icon fa fa-phone"></i> 354-6979385 or
                            354-7811260
                          </a>
                        </li>
                        <li className="email">
                          <a href="mailto:info@eleganttours.is">
                            <i className="icon fa fa-envelope"></i>{" "}
                            info@eleganttours.is
                          </a>{" "}
                          or{" "}
                          <a href="mailto:josephoyeniyiajayi@gmail.com">
                            <i className="icon fa fa-envelope"></i>{" "}
                            josephoyeniyiajayi@gmail.com
                          </a>
                        </li>
                      </ul>
                      <ul className="social-links clearfix">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/profile.php?id=100051682856536"
                            className="facebook"
                            rel="noreferrer">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/elegant_tours_is?igsh=bjZxcnEyb3dudDZh&utm_source=qr"
                            className="instagram"
                            rel="noreferrer">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        {/* <li><a href="#" className="twitter"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#" className="linkedin"><i className="fab fa-linkedin-in"></i></a></li> */}
                        <li>
                          <a
                            target="_blank"
                            href="https://www.youtube.com/channel/UCXu9DSA4O7qzysxUcNn5daA"
                            className="youtube"
                            rel="noreferrer">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                  <div className="row clearfix">
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget links-widget">
                        <h4>Top Destination</h4>
                        <div className="links">
                          <ul>
                            {tours
                              .sort(function () {
                                return 0.5 - Math.random();
                              })
                              .slice(0, 5)
                              .map((tour, i) => {
                                return (
                                  <li>
                                    <NavLink
                                      to={"/tours/" + tour.id}
                                      state={{ tour }}>
                                      {tour.name}
                                    </NavLink>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget links-widget">
                        <h4>Airport Transfer</h4>
                        <div className="links">
                          <ul>
                            {transfer
                              .sort(function () {
                                return 0.5 - Math.random();
                              })
                              .slice(0, 5)
                              .map((transfer, i) => {
                                return (
                                  <li>
                                    <NavLink to={"/transfer"}>
                                      {transfer.name}
                                    </NavLink>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget news-widget">
                    <h4>Cool Tours</h4>
                    <div className="news">
                      {tours
                        .sort(function () {
                          return 0.5 - Math.random();
                        })
                        .slice(0, 2)
                        .map((tour, i) => {
                          return (
                            <div className="news-post">
                              <div className="image">
                                <NavLink
                                  to={"/tours/" + tour.id}
                                  state={{ tour }}>
                                  <MyImage
                                    src={require("../../Assets/img/resource/tours/" +
                                      tour.image)}
                                    alt=""
                                  />
                                </NavLink>
                              </div>
                              <h6>
                                <NavLink
                                  to={"/tours/" + tour.id}
                                  state={{ tour }}>
                                  {tour.name}
                                </NavLink>
                              </h6>
                              <div className="price">
                                Small Vehicle{" "}
                                <span className="amount">
                                  {tour.small_vehicle}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="f-bottom">
          <div className="auto-container">
            <div className="inner clearfix">
              <div className="copyright">
                All rights reserved{" "}
                <strong>Reykjavik Elegant Private Tour</strong> &copy; 2023
              </div>
              <div className="bottom-links">
                {/* <ul className="clearfix">
                                    <li><a href="#">Terms & Condition</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Legal</a></li>
                                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
