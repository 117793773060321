import React from "react";
import { NavLink, Link } from "react-router-dom";
import Nav from "./Nav";
import MyImageSvg from "../../Assets/img/menu-icon.svg";

const Header = () => {
  const [open, setOpen] = React.useState("");

  return (
    <>
      <header className="main-header header-style-two">
        <div className="header-top">
          <div className="auto-container">
            <div className="inner clearfix">
              <div className="top-left clearfix">
                <ul className="info clearfix">
                  <li>
                    <i className="icon fa fa-envelope"></i>{" "}
                    <a href="mailto:info@eleganttours.is">
                      info@eleganttours.is
                    </a>
                  </li>
                  <li>
                    <i className="icon fa fa-map-marker-alt"></i>{" "}
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Su%C3%B0urh%C3%B3lar+18+111,+111+Reykjav%C3%ADk,+Iceland/@64.1072716,-21.8162763,17z/data=!3m1!4b1!4m5!3m4!1s0x48d673725d21528b:0x3b4d690b08e53447!8m2!3d64.1072716!4d-21.8137014"
                      rel="noreferrer">
                      Sudurholar 18. 111 Reykjavik. Iceland.
                    </a>
                  </li>
                </ul>
              </div>
              <div className="top-right clearfix">
                <div className="social">
                  <ul className="social-links clearfix">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100051682856536"
                        className="facebook"
                        rel="noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/elegant_tours_is?igsh=bjZxcnEyb3dudDZh&utm_source=qr"
                        className="instagram"
                        rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="twitter">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="linkedin">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li> */}
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCXu9DSA4O7qzysxUcNn5daA"
                        className="youtube"
                        rel="noreferrer">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-upper">
          <div className="auto-container">
            <div className="main-box clearfix">
              <div className="logo-box">
                <div className="logo">
                  <Link to="/" title="Elegant Tours">
                    <img
                      src={require("../../Assets/images/logo.png")}
                      alt=""
                      title="Travilo"
                    />
                  </Link>
                </div>
              </div>

              <div className="outer clearfix">
                <div className="nav-box clearfix">
                  <div className="nav-outer clearfix">
                    <nav className="main-menu">
                      <Nav />
                    </nav>
                  </div>
                </div>

                <div className="links-box clearfix">
                  <div className="link call-to">
                    <a href="tel:+3546979385">
                      <i className="icon fa fa-phone"></i> Call Us{" "}
                      <span className="nmbr">+354-6979385</span>
                    </a>
                  </div>
                </div>

                <div
                  className="nav-toggler"
                  onClick={() => {
                    setOpen(true);
                  }}>
                  <button className="hidden-bar-opener">
                    <span className="icon">
                      <img src={MyImageSvg} alt="" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="menu-backdrop"></div>

      <section className={open ? "hidden-bar visible-sidebar" : "hidden-bar"}>
        <div className="hidden-bar-wrapper">
          <div
            className="hidden-bar-closer"
            onClick={() => {
              setOpen(false);
            }}>
            <span className="icon">
              <svg
                className="icon-close"
                role="presentation"
                viewBox="0 0 16 14">
                <path
                  d="M15 0L1 14m14 0L1 0"
                  stroke="currentColor"
                  fill="none"
                  fill-rule="evenodd"></path>
              </svg>
            </span>
          </div>
          <div className="nav-logo-box">
            <div className="logo">
              <a href="index.html" title="Travilo">
                <img
                  src={require("../../Assets/images/logo.png")}
                  alt=""
                  title="Travilo"
                />
              </a>
            </div>
          </div>
          <div className="side-menu">
            <Nav />
          </div>

          <div className="links-box clearfix">
            <div className="clearfix">
              <div className="link">
                <NavLink to="tours" className={"theme-btn btn-style-one"}>
                  <span>Book a Tour</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
